<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xl="http://www.w3.org/1999/xlink"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    version="1.1"
    viewBox="206 212 265 265"
    :width="size.width"
    :height="size.height"
  >
    <defs />
    <g
      stroke="none"
      stroke-opacity="1"
      stroke-dasharray="none"
      fill="none"
      fill-opacity="1"
    >
      <title>WoTime</title>
      <g>
        <title>Logo</title>
        <path
          d="M 206.92914 268.39433 L 206.92914 476.22047 L 470.5512 476.22047 L 470.5512 212.59842 L 445.5526 212.59842 L 390.01424 331.70076 C 385.34613 341.71156 373.44652 346.04266 363.43572 341.37455 Z"
          :fill="color"
        />
        <path
          d="M 206.92913 253.83908 L 361.1602 325.7582 C 361.1602 325.7582 361.1602 325.7582 361.1602 325.7582 C 366.1656 328.09227 372.1154 325.9267 374.44947 320.9213 L 424.96127 212.59842 L 206.92913 212.59842 Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import logo from './logo';
export default {
  name: 'Logo',
  mixins: [logo],
};
</script>
