export default {
  props: {
    color: { type: String, default: '#972d15' }, //  a41f00
    width: { type: Number },
    height: { type: Number },
  },
  computed: {
    size() {
      const width = this.width;
      const height = this.height;

      const w = 263;
      const h = 263;

      if (width && height) {
        return { width, height };
      } else if (height) {
        return { width: Math.round((height / h) * w), height };
      } else if (width) {
        return { width, height: Math.round((width / w) * h) };
      } else {
        return { width: w, height: h };
      }
    },
  },
};
